.main {
  margin: 0;
  min-height: 100vh;
}

.fra-green {
  margin-top: 25px;
  height: 100px;
  width: 100%;
  background-color: rgb(42, 181, 179);
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  color: white;
  text-align: center;
}

.fra-blue {
  display: block;
  height: 200px;
  background-color: rgb(9, 108, 147);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fra-blue>div>div>img {
  margin-right: 5px;
}

.fra-blue>div>div {
  margin-bottom: 10px;
}

.fra-blue>div>div>a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(106, 66, 19);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(9, 108, 147);
}