
#inicio {
    width: 100%;
    min-height: 100vh;
}

#content-page1 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

#inicio .img-content{
    position: relative;
    text-align: center;
}

#content-page1 .img {
    margin-top: 20px;
    width: 100%;
}

#content-page1 .title-img {
    pointer-events: none;
    text-decoration: none;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 24px;
    background-color: rgb(106, 66, 19);
    color: rgb(249, 178, 45);
    border-radius: 5px;
  }

#content-page1 .lnk-img {
    display: inline-block;
    position: absolute;
    width: 100px;
    bottom: 5px;
    height: 40px;
    left: 45%;
    right: 40%;
    cursor: pointer;
}

#content-bottom {
    margin-top: 10px!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-bottom{
    font-size: 24px;
    display: block;
    color: rgb(9, 108, 147);
    margin-bottom: 5px;
}

.t-content {
    color: rgb(106, 66, 19);
}   

.t-group {
    background-color: rgb(206, 206, 206);
}


@media only screen and (max-width: 480px) {
    
}