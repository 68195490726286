
#preguntas {
    min-height: 100vh;
    padding-top: 50px;
}

#preguntas .content {
    margin: auto;
    max-width: 800px;
}

#preguntas .title-preg {
    text-align: center;
    font-size: 24px;
    color: rgb(106, 66, 19);
    font-weight: 700;
  }

  #preguntas .preg {
    font-size: 24px;
    color: rgb(9, 108, 147);
  }
  
  #preguntas .preg-group {
    margin-top: 10px;
  }
  
  #preguntas .resp {
    font-size: 18px;
    color: rgb(106, 66, 19);
  }