#citas {
  min-height: 100vh;
  padding-top: 50px;
}

#citas .form {
  position: relative;
  color: white;
}

#citas .form-content {
  border-radius: 5px;
  padding: 30px;
  background-color: rgb(9, 108, 147);
}

#citas .form-title {
  font-size: 30px;
  color: white;
  text-align: center;
}

#citas .col-main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#citas .col-space {
  display: none;
  height: 10px;
}

#citas .step {
  color: white;
  min-height: 50px;
}

#citas .step-2 {
  color: white;
  min-height: 50px;
  margin-bottom: 10px;
}

#citas .footer {
  margin-top: 10px;
  color: white;
}

#citas .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
  gap: 15px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #2AB5B3 !important;
  border-color: #2AB5B3;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:visited {
  border-color: #2AB5B3;
  color: #2AB5B3;
}

@media only screen and (max-width: 780px) {
  #citas .col-space {
    display: block;
  }
}
/*
@media only screen and (max-width: 450px) {
  #citas .step {
    height: auto;
  }
  #citas .step-2 {
    height: auto;
  }
} */