
#navbar {
    top: 0;
    position: sticky;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(249, 178, 45);
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }
  
  #navbar .header {
    display: flex ;
    flex-direction: row;
    padding: 5px 20px;
  }

  #navbar .logo {
      height: 100%;
  }

  #navbar .header-left {
    display: flex;
  }

  #navbar .subTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: rgb(106, 66, 19);
  }

  #navbar .div-vert{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-left: 3px solid rgb(253, 232, 187);
    display: inline-block;
  }

  #navbar .link {
    font-size: 18px;
    font-weight: 500;
    color: rgb(106, 66, 19);
    text-decoration: none;
    margin-right: 10px;
    padding: 7px;
    cursor: pointer;
    word-wrap: normal;
  }
  
  #navbar .menu {
    display: flex;
  }
  
  #navbar .link:hover {
    background-color: rgb(106, 66, 19);
    color: rgb(249, 178, 45);
  }

  @media only screen and (max-width: 780px) {
    #navbar {
      flex-direction: column;
      padding-bottom: 10px;
      align-items:unset;
    }

    #navbar .logo {
        height: 30px;
    }

    #navbar .title {
        height: 30px;
    }
  
    #navbar .subTitle{ 
        font-size: 12px;
        font-weight: 400;
    }

    #navbar .menu {
      justify-content: end;
    }

    #navbar .link {
        font-size: 12px;
    }

    #navbar .div-vert{
        border-left: 1px solid rgb(253, 232, 187);
      }
  }