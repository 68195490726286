*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control::placeholder {
  color: rgb(9, 108, 147);
  opacity: 1; 
}

.form-control:-ms-input-placeholder {
  color: rgb(9, 108, 147);
}

.form-control::-ms-input-placeholder { 
  color: rgb(9, 108, 147);
}

.form-control {padding:10px !important;}

.form-select {padding:10px !important;}

select{
  color: rgb(9, 108, 147)!important;
}

@media only screen and (max-width: 780px) {
  .form-control {padding:5px !important;}
  .form-select {padding:5px !important;}
}
